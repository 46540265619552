import React from 'react';
import '../styles/Dashboard.css'; // Ensure this CSS file is created and imported
import FhpIcon from '../assets/banner.png'; // Ensure this image file is created and imported
import banner from '../assets/banner.png';
import bigmike from '../assets/bigmike.png';
import blacked from '../assets/blacked.png';
import couch from '../assets/couch.png';
import disco from '../assets/disco.png';
import fhp from '../assets/fhp.png';
import money from '../assets/money.png';
import ph from '../assets/ph.png';
import pole from '../assets/pole.png';


const Dashboard = () => {
  return (
    <div>
      <div className="dashboard">
      <h1 className="dashboard-header cedarville-cursive-regular">Fat Hairy Pussy</h1>
      <img src={FhpIcon} alt="fhp" className="fhp"></img>
      <div className="dashboard-buttons">
      <a href="https://twitter.com/fhponsol" target="_blank" rel="noopener noreferrer">
        <button className="button-74">Twitter</button>
      </a>
      <a href="https://t.me/FHPPortal" target="_blank" rel="noopener noreferrer">
        <button className="button-74">Telegram</button>
      </a>
      <a href="https://www.dextools.io/app/en/solana/pair-explorer/8MFur1QhXHxjBPTTgSfQG7WqLVxBLSwMyYdnieK3dEEr?t=1718947334588" target="_blank" rel="noopener noreferrer">
        <button className="button-74">Dex</button>
      </a>
      </div>
    </div>
    <div className="gif-main-container">
        <div className="gif-container">
        <img className='gifImage' src={bigmike} alt="Big Mike" />
        <img className='gifImage' src={blacked} alt="Blacked" />
        <img className='gifImage' src={couch} alt="Couch" />
        <img className='gifImage' src={disco} alt="Disco" />
        <img className='gifImage' src={fhp} alt="FHP" />
        <img className='gifImage' src={money} alt="Money" />
        <img className='gifImage' src={ph} alt="PH" />
        <img className='gifImage' src={pole} alt="Pole" />
        </div>
    </div>
    </div>
  );
};

export default Dashboard;
