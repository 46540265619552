import './App.css';
import Dashboard from './components/Dashboard';
import FphBounca from './components/FphBounca';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Dashboard></Dashboard>
      <FphBounca></FphBounca>
      <Footer></Footer>
    </div>
  );
}

export default App;
