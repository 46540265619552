import React, { useEffect, useState } from 'react';
import Fz from '../assets/fhp.png';

const FphBounca = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [velocity, setVelocity] = useState({ dx: 4, dy: 4 });

    const imgWidth = 220 + window.innerWidth * 0.1;
    const imgHeight = 220 + window.innerWidth * 0.1;

    useEffect(() => {
        const handleAnimation = () => {
            setPosition((prevPosition) => {
                let newPosition = {
                    x: prevPosition.x + velocity.dx,
                    y: prevPosition.y + velocity.dy,
                };

                let newVelocity = { ...velocity };

                // Bounce off the walls
                if (newPosition.x <= 0 || newPosition.x + imgWidth >= window.innerWidth) {
                    newVelocity.dx = -velocity.dx;
                }

                if (newPosition.y <= 0 || newPosition.y + imgHeight >= window.innerHeight) {
                    newVelocity.dy = -velocity.dy;
                }

                // Ensure the position stays within bounds
                newPosition.x = Math.max(0, Math.min(newPosition.x, window.innerWidth - imgWidth));
                newPosition.y = Math.max(0, Math.min(newPosition.y, window.innerHeight - imgHeight));

                setVelocity(newVelocity);
                return newPosition;
            });
        };

        const animationId = setInterval(handleAnimation, 10);

        return () => {
            clearInterval(animationId);
        };
    }, [velocity, imgWidth, imgHeight]);

    useEffect(() => {
        const handleScroll = () => {
            setPosition((prevPosition) => ({
                x: prevPosition.x + (window.scrollX - window.pageXOffset),
                y: prevPosition.y + (window.scrollY - window.pageYOffset),
            }));
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
    }, [velocity]);

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 1000,
                top: position.y,
                left: position.x,
                transition: 'transform 0.1s ease-in-out'
            }}
        >
            <img src={Fz} alt="Bouncing FHP" width={imgWidth} height={imgHeight} className="spinning" />
        </div>
    );
};

export default FphBounca;
