import React from 'react';
import '../styles/Footer.css'; // Ensure this CSS file is created and imported

const Footer = () => {
  return (
    <div className="footer">
      <div className="sliding-text">
        <div className="text-container">
          $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP
        </div>
        <div className="text-container">
          $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP $FHP
        </div>
      </div>
    </div>
  );
};

export default Footer;
